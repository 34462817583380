const endpoints = {
  base: 'https://hubtest.tnc.org/api',
};

export const environment = {
  production: false,
  name: 'Hub (Test)',
  endpoints,
  algolia: {
    appId: 'WRFJJH1WEP',
    portfolioSearch: {
      indexName: 'tst_hub4_portfoliosearch',
      routing: true,
      temporarySecretKeyEndpoint: `${endpoints.base}/AlgoliaKey/tst_hub4_portfoliosearch`,
    },
    projectNameSearch: {
      indexName: 'tst_hub4_projectnames',
      routing: false,
      temporarySecretKeyEndpoint: `${endpoints.base}/AlgoliaKey/tst_hub4_projectnames`,
    },
    collectionSearch: {
      indexName: 'tst_hub4_projectcollectionsearch',
      routing: false,
      temporarySecretKeyEndpoint: `${endpoints.base}/AlgoliaKey/tst_hub4_projectcollectionsearch`,
    },
    fundraisingMenuSearch: {
      indexName: 'tst_hub4_fundraisingmenu',
      routing: true,
      temporarySecretKeyEndpoint: `${endpoints.base}/AlgoliaKey/tst_hub4_fundraisingmenu`,
    },
    userAdministration: {
      indexName: 'tst_hub4_user',
      routing: true,
      temporarySecretKeyEndpoint: `${endpoints.base}/AlgoliaKey/tst_hub4_user`,
    },
    projectAtTncSearch: {
      indexName: 'css_dev_projectsattnc',
      routing: true,
      temporarySecretKeyEndpoint: `${endpoints.base}/AlgoliaKey/css_dev_projectsattnc`,
    },
  },
  auth: {
    tenant: '79be6dc1-d78e-4bbb-b22b-d994c0a417a7',
    clientId: 'a0680fbb-f6c4-4652-b439-6b2636b4141a',
    redirectUri: 'https://hubtest.tnc.org/auth-callback',
    domain: 'hubtest.tnc.org',
    requireHttpsProtocol: true,
    endpoints: {
      'https://localhost/61642': '767fb58b-cde9-42b1-8881-44665d5a9c81',
      'https://localhost/8080': '767fb58b-cde9-42b1-8881-44665d5a9c81',
    },
  },
  errorHandling: {
    display: {
      autohide: {
        delayInSeconds: 3,
      },
    },
    logging: null,
  },
  showNonProdNotification: true,
  appInsights: {
    connectionString: 'InstrumentationKey=0a21bb9d-624c-4551-8543-ea97ecc766fd;IngestionEndpoint=https://eastus-0.in.applicationinsights.azure.com/',
  },
  timers: {
    showWarningBeforeTimeoutInMinutes: 5,
  },
  amCharts: {
    license: 'CH304356357',
  },
  featureToggles: {},
};
